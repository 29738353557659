<template>
  <div>
    <h1 class="modal-detail-title">Configuración</h1>
    <b-container class="detail--carrier--header">
      <b-row>
        <b-col>
          <p class="mb-08">País</p>
          <p class="mb-0">{{detail.country_name}}</p>
        </b-col>
        <b-col>
          <p class="mb-08">Nombre Courier</p>
          <p class="mb-0">{{detail.carrier_name}}</p>
        </b-col>
        <b-col>
          <p class="mb-08">Código Courier</p>
          <p class="mb-0">{{detail.carrier_code}}</p>
        </b-col>
        <b-col>
          <p class="mb-08">Código Envíame</p>
          <p class="mb-0">{{detail.enviame_code}}</p>
        </b-col>
        <b-col>
          <p class="mb-08">Activado</p>
          <p class="mb-0">{{detail.state ? 1 : 0}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="mb-08">Código Servicio </p>
          <p class="mb-0">Esta data Falta</p>
        </b-col>
        <b-col>
          <p class="mb-08">Categoría Servicio  </p>
          <p class="mb-0">{{detail.carrier_service_type_code}}</p>
        </b-col>
        <b-col>
          <p class="mb-08">Tipo de Negocio </p>
          <p class="mb-0">{{detail.contract_type_code}}</p>
        </b-col>
        <b-col>
          <p class="mb-08">Courier Manager  </p>
          <p class="mb-0">{{detail.contact.carrier_manager.name}}</p>
        </b-col>
        <b-col></b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'
export default {
  props: ['detail'],
  components: {
    BContainer,
    BRow,
    BCol
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-detail-title {
    text-align: left;
    // font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #5E5873;
    margin-bottom: 2.3rem;
  }

  .detail--carrier--header .col {
    width: 20%;
    padding: 0 .8rem 1.8rem 0;
    // font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1rem;
    color: #737080;
    text-align: left;
  }
  // .detail--carrier--header .col .p {
  //   margin-bottom: .8rem;
  // }
  .detail--carrier--header .row {
    margin: 0;
    word-break: break-all;
  }
</style>