<template>
  <div>
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="compensationConfigForm" :fields="fieldsForm">
        </form-render>
      </template>
    </filter-swapper>
  </div>
</template>
<script>
export default {
  props: ['compensationConfig'],
  data() {
    return {
      compensationConfigForm: {},
      fieldsForm: [],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      formUpdate: {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    compensationConfigForm: {
      handler(value) {
        this.updateForm(value)
      },
      deep: true
    }
  },
  methods: {
    setInitialData() {
      this.fieldsForm = [
        {fieldType: 'FieldInput', name: 'discount_earnings', label: '% Descuento Lucro Cesante', containerClass: 'col-12 col-md-4 container-info', icon: 'PercentIcon', type:'number'},
        {fieldType: 'FieldInput', name: 'discount_iva', label: '% Descuento IVA', containerClass: 'col-12 col-md-4 container-info', icon: 'PercentIcon', type:'number'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'compensation_limit_value', label: 'Valor Limite Compensación', containerClass: 'col-12 col-md-4 container-info', type:'number'},
        {fieldType: 'FieldInput', name: 'compensation_limit_unit', label: 'Unidad Limite Compensación', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'compensation_object', label: 'Objeto de Compensación', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'minimum_amount_invoice_value', label: 'Valor limite Compensación (SEGURO)', containerClass: 'col-12 col-md-4 container-info', type:'number'},
        {fieldType: 'FieldInput', name: 'minimum_amount_invoice_currency', label: 'Unidad limite Compensación (SEGURO)', containerClass: 'col-12 col-md-4 container-info'}
      ]

      this.setFormData(this.compensationConfig)
    },

    setFormData(data) {
      if (data) this.compensationConfigForm = {...data, 
        compensation_limit_value: data.compensation_limit.value,
        compensation_limit_unit: data.compensation_limit.unit,
        minimum_amount_invoice_value: data.minimum_amount_invoice.value,
        minimum_amount_invoice_currency: data.minimum_amount_invoice.currency
      }
    },
    updateForm(value) {
      const data = {
        discount_earnings: parseInt(value.discount_earnings),
        discount_iva: parseInt(value.discount_iva),
        compensation_limit: {
          value: parseInt(value.compensation_limit_value),
          unit: value.compensation_limit_unit
        },
        compensation_object: value.compensation_object,
        minimum_amount_invoice: {
          value: parseInt(value.minimum_amount_invoice_value),
          currency: value.minimum_amount_invoice_currency
        }
      }
      this.$emit('formCompensation', data)
    }
  }
}
</script>
<style lang="scss">
  .collapse--space {
    visibility: collapse;
  }
</style>