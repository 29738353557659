<template>
  <div>
    <app-collapse accordion class="row block version--list">
      <app-collapse-item title="">
        <template #header>
          <div class="container-info">
            <a class="cursor-pointer lnk lnk-primary"><feather-icon icon="ClockIcon"/> Historial de versiones </a>
          </div>
        </template>
        <b-card>
          <b-container>
            <b-row v-for="(version, index) of versionList" :key="index">
              <b-col cols="4" class="align-items-center">
                <p class="mb-0">
                  <template v-if="version.is_current">
                    <b-icon class="mr-1"  icon="check-circle-fill" variant="success"></b-icon> 
                    <span>Version actual: </span>
                  </template>
                  <template v-else>
                    <b-icon class="mr-1" icon="dash-circle-fill" variant="secondary"></b-icon>
                    <span>Version</span>
                  </template>
                  <span>Creada el <span>{{formatDate(version.created_at)}}</span></span>
                </p>
              </b-col>
              <b-col class="align-items-center">
                <b-button  @click="viewDetail(version.id)" class="p-0 version--list-detail" variant="link">Ver Detalle</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </app-collapse-item>
    </app-collapse>
    <modal-version-carrier :detail="versionDetail"></modal-version-carrier>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
import ModalVersionCarrier from './ModalVersionDetail.vue'
import {
  BIcon,
  BIconCheckCircleFill,
  BIconDashCircleFill,
  BRow,
  BCol,
  BContainer
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['versionList', 'idCarrier'],
  data() {
    return {
      versionDetail: {},
      baseService: new BaseServices(this)
    }
  },
  components: {BIcon, BIconCheckCircleFill, BIconDashCircleFill, BRow, BCol, BContainer, ModalVersionCarrier},
  methods: {
    formatDate(date) {
      return `${this.$options.filters.dbDateToFormat(date, 'dd/LL/yyyy')} a las ${this.$options.filters.dbDateToFormat(date, 'hh:mm')}`
    },
    viewDetail(id) {
      const params = {
        shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
        id_carrier: this.idCarrier,
        id_version: id
      }
      const queryParams = {}
      this.baseService.callService('getDetailVersion', queryParams, params)
        .then(response => {
          this.versionDetail = {...response.data[0]}
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err}))
        })
        .finally(() => {
          this.$bvModal.show('modalVersionCarrier')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  .version--list .row+.row {
    margin-top: 10px;
  }

  .version--list .version--list-detail {
    border-bottom: 1px solid #044389;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

</style>