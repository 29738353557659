<template>
  <div>
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="stageTimeForm" :fields="fieldsForm">
          <template #carrierManagerNameSlot>
            <select-input :valueInput="stageTimeForm.time_information" :conditions="selectInputConditions.carrierManagerConditions" @updateDataSelectorInput="updateInfoForm($event,'time_information')"></select-input>
          </template>
          <template #issueSlot>
            <select-input :valueInput="stageTimeForm.time_issue" :conditions="selectInputConditions.issueConditions" @updateDataSelectorInput="updateInfoForm($event,'time_issue')"></select-input>
          </template>
          <template #complaintSlot>
            <select-input :valueInput="stageTimeForm.time_complaint" :conditions="selectInputConditions.complaintConditions" @updateDataSelectorInput="updateInfoForm($event,'time_complaint')"></select-input>
          </template>
          <template #compensationSlot>
            <select-input :valueInput="stageTimeForm.time_compensation" :conditions="selectInputConditions.compensationConditions" @updateDataSelectorInput="updateInfoForm($event,'time_compensation')"></select-input>
          </template>
          <template #disagreementSlot>
            <select-input :valueInput="stageTimeForm.time_disagreement" :conditions="selectInputConditions.disagreementConditions" @updateDataSelectorInput="updateInfoForm($event,'time_disagreement')"></select-input>
          </template>
          <template #typeCompensationResponseSlot>
              <b-form-checkbox
                v-model="stageTimeForm.type_compensation_response.value"
                value="variable"
                switch
                unchecked-value="fija"
              >
                {{textValueSwitch}}
              </b-form-checkbox>
              <p class="m-0">
                <span class="d-block">Fija = respuestas se esperan en un periodo determinado</span>
                <span class="d-block">Variable = respuestas en un periodo indeterminado, necesita revision constante</span>
              </p>
          </template>
        </form-render>
      </template>
    </filter-swapper>
  </div>
</template>
<script>
import SelectInput from './SelectInput.vue'
export default {
  props: ['stageTime'],
  components: {SelectInput},
  data() {
    return {
      stageTimeForm: {},
      fieldsForm: [],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      formUpdate: {},
      selectInputConditions: {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    textValueSwitch() {
      return this.stageTimeForm.type_compensation_response.value === 'variable' ? 'Variable' : 'Fija'
    }
  },
  watch: {
    'stageTimeForm': {
      handler(value) {
        value.delivery_attempts = parseInt(value.delivery_attempts)
        this.$emit('formTimeStage', value)
      },
      deep: true
    },
    'stageTimeForm.time_information': {
      handler(value) {
        this.$emit('formTimeStage', {time_information: {...value}})
      },
      deep: true
    },
    'stageTimeForm.time_issue': {
      handler(value) {
        this.$emit('formTimeStage', {time_issue: {...value}})
      },
      deep: true
    },
    'stageTimeForm.time_complaint': {
      handler(value) {
        this.$emit('formTimeStage', {time_complaint: {...value}})
      },
      deep: true
    },
    'stageTimeForm.time_compensation': {
      handler(value) {
        this.$emit('formTimeStage', {time_compensation: {...value}})
      },
      deep: true
    },
    'stageTimeForm.time_disagreement': {
      handler(value) {
        this.$emit('formTimeStage', {time_disagreement: {...value}})
      },
      deep: true
    },
    'stageTimeForm.type_compensation_response': {
      handler(value) {
        this.$emit('formTimeStage', {type_compensation_response: {...value}})
      },
      deep: true
    }
  },
  methods: {
    setInitialData() {
      this.fieldsForm = [
        {useSlot:true, name: 'carrierManagerNameSlot', label: 'Espera Información Courier', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'delivery_attempts', label: 'Intentos de entrega', containerClass: 'col-12 col-md-4 container-info', type:'number'},
        {useSlot:true, name: 'issueSlot', label: 'Problema', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'complaintSlot', label: 'Reclamo', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'compensationSlot', label: 'Indemnización', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'disagreementSlot', label: 'Discrepancia', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'typeCompensationResponseSlot', label: 'Tipo de respuesta de indemnización', containerClass: 'col-12 container-info m-0'}
      ]
      this.selectInputConditions = {
        carrierManagerConditions: this.setConditionsSelectInput('time', 'Ingrese Tiempo Etapa Espera Información Courier'),
        issueConditions: this.setConditionsSelectInput('time', 'Ingrese Tiempo de Etapa Problema'),
        complaintConditions: this.setConditionsSelectInput('time', 'Ingrese Tiempo de Etapa de Reclamo'),
        compensationConditions: this.setConditionsSelectInput('time', 'Ingrese Tiempo de Etapa de Indemnización'),
        disagreementConditions: this.setConditionsSelectInput('time', 'Ingrese Tiempo de Etapa de Discrepancia')
      }
      this.setFormData(this.stageTime)
    },
    setConditionsSelectInput(type, placeholder, required = '') {
      return {
        type,
        placeholder,
        required
      }
    },
    setFormData(data) {
      if (data) this.stageTimeForm = {
        delivery_attempts: data.delivery_attempts ? parseInt(data.delivery_attempts) : 0,
        time_information: {
          time_type: data.time_information.time_type ?? 'DAYS',
          value: data.time_information.value ? parseInt(data.time_information.value) : 0
        },
        time_issue: {
          time_type: data.time_issue.time_type ?? 'DAYS',
          value: data.time_issue.value ? parseInt(data.time_issue.value) : 0
        },
        time_complaint: {
          time_type: data.time_complaint.time_type ?? 'DAYS',
          value: data.time_complaint.value ? parseInt(data.time_complaint.value) : 0
        },
        time_compensation: {
          time_type: data.time_compensation.time_type ?? 'DAYS',
          value: data.time_compensation.value ? parseInt(data.time_compensation.value) : 0
        },
        time_disagreement: {
          time_type: data.time_disagreement.time_type ?? 'DAYS',
          value: data.time_disagreement.value ?  parseInt(data.time_disagreement.value) : 0
        },
        type_compensation_response: {
          value: data.type_compensation_response?.value ?? 'fija'
        }
      }
    },
    updateInfoForm(value, source) {
      if ('time_type' in this.stageTimeForm[source] && value.selector && value.selector.id) this.stageTimeForm[source].time_type = value.selector.id 
      if ('unit' in this.stageTimeForm[source] && value.selector && value.selector.id) this.stageTimeForm[source].unit = value.selector.id 
      if (value.textValue) this.stageTimeForm[source].value = parseInt(value.textValue)
    }
  }
}
</script>
<style lang="scss">

</style>