<template>
  <div>
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="managementPickupsForm" :fields="fieldsForm">
        </form-render>
      </template>
    </filter-swapper>
    <b-alert class="p-1 mt-1 col-md-4" variant="warning" show>
      <div class="d-flex  align-items-center">
        <feather-icon size="30" class="mr-50" icon="InfoIcon"/>
        <span>
          Este formulario es independiente del resto. 
          Si se hace un cambio, no se guardarán los demás formularios.
        </span>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['communication'],
  data() {
    return {
      managementPickupsForm: {},
      fieldsForm: [],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      formUpdate: {},
      optionsPickupTypes: [
        {
          text: 'Retiro Diario',
          id: 'normal'
        }
      ],
      optionsPickupActions: [
        {
          text: 'Cancelar retiro',
          id: 'cancel_pickup'
        }
      ],
      optionsPickupActionDates: [
        {
          text: 'Fecha en la que se creó el retiro',
          id: 'pickup_date'
        },
        {
          text: 'Fecha de corte del retiro',
          id: 'pickup_deadline'
        }
      ],
      baseService: new BaseServices(this)
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    'managementPickupsForm.cancel_deadline': {
      handler(value) {
        this.updateForm(value)
      },
      deep: true
    }
  },
  methods: {
    setInitialData() {
      this.fieldsForm = [
        {
          fieldType: 'FieldSelect', 
          name: 'pickup_type', 
          label: 'Tipos de retiro', 
          validation: 'required',
          clearable: false,
          containerClass: 'col-12 col-md-4 container-info', 
          options: this.optionsPickupTypes
        },
        {
          fieldType: 'FieldSelect', 
          name: 'pickup_action', 
          label: 'Acciones de retiro', 
          validation: 'required',
          clearable: false,
          containerClass: 'col-12 col-md-4 container-info', 
          options: this.optionsPickupActions
        },
        {
          fieldType: 'FieldInput',
          type: 'number',
          append: { text: 'Minutos', color: '#BBBBBB' },
          name: 'cancel_deadline',
          label: 'Plazo de cancelación',
          validation: 'required',
          containerClass: 'col-12 col-md-4 container-info'
        }
      ]
      this.managementPickupsForm = {
        pickup_type: {
          text: 'Retiro Diario',
          id: 'normal'
        },
        pickup_action: {
          text: 'Cancelar retiro',
          id: 'cancel_pickup'
        }
      }
      this.getCarrierconfigs()
    },
    getCarrierconfigs() {
      this.baseService.callService('getCarrierConfiguration', null, { carrier_code: this.communication.carrier_code })
        .then(response => {
          const minutesBefore = response.data.customization.cancelation_config.params.minutes_before_time_limit
          this.managementPickupsForm = {
            ...this.managementPickupsForm,
            cancel_deadline: minutesBefore
          }
        })
        .catch(err => {
          this.managementPickupsForm = {
            ...this.managementPickupsForm,
            cancel_deadline: 0
          }
        })
    },
    updateForm(minutes) {
      this.formUpdate = {
        minutes_before_time_limit: minutes
      }
      this.$emit('formManagePickup', this.formUpdate)
    }
  }
}
</script>
<style lang="scss">
</style>