<template>
  <div>
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="complaintTimeForm" :fields="fieldsForm">
          <template #damageMissingSlot>
            <select-input :valueInput="complaintTimeForm.time_damage_missing" :conditions="selectInputConditions.damageMissingCondition" @updateDataSelectorInput="updateInfoForm($event,'time_damage_missing')"></select-input>
          </template>
          <template #unawarenessSlot>
            <select-input :valueInput="complaintTimeForm.time_claim_unawareness_delivery" :conditions="selectInputConditions.unawarenessCondition" @updateDataSelectorInput="updateInfoForm($event,'time_claim_unawareness_delivery')"></select-input>
          </template>
          <template #waitDocumentSlot>
            <select-input :valueInput="complaintTimeForm.time_wait_document" :conditions="selectInputConditions.waitDocumentCondition" @updateDataSelectorInput="updateInfoForm($event,'time_wait_document')"></select-input>
          </template>
          <template #insuranceMoneySlot>
            <select-input :valueInput="complaintTimeForm.compensation_limit_insurance" :conditions="selectInputConditions.insuranceMoneyCondition" @updateDataSelectorInput="updateInfoForm($event,'compensation_limit_insurance')"></select-input>
          </template>
          <template #restudySlot>
            <select-input :valueInput="complaintTimeForm.time_restudy" :conditions="selectInputConditions.restudyTimeCondition" @updateDataSelectorInput="updateInfoForm($event,'time_restudy')"></select-input>
          </template>
        </form-render>
      </template>
    </filter-swapper>
  </div>
</template>
<script>
import SelectInput from './SelectInput.vue'
export default {
  props: ['complaintTime'],
  components: {SelectInput},
  data() {
    return {
      complaintTimeForm: {},
      fieldsForm: [],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      formUpdate: {},
      selectInputConditions: {},
      optionsDocumentDate: [
        {
          id: 'Fecha de compromiso',
          text: 'Fecha de compromiso',
          color: 'black'
        },
        {
          id: 'Fecha del ultimo estado de tracking',
          text: 'Fecha del último estado de tracking',
          color: 'black'
        },
        {
          id: 'Fecha de estado del ticket',
          text: 'Fecha de estado del ticket',
          color: 'black'
        }
      ],
      optionsPlatform: [{id: true, text: 'Si'}]
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    'complaintTimeForm': {
      handler(value) {
        const sendForm = {...value, 
          date_init_wait_document: value.date_init_wait_document && value.date_init_wait_document.id ?  value.date_init_wait_document.id : this.setObjetOptionDocument(this.complaintTimeForm.date_init_wait_document)[0].id,
          date_init_disagreement_stage: value.date_init_disagreement_stage && value.date_init_disagreement_stage.id ?  value.date_init_disagreement_stage.id : this.setObjetOptionDocument(this.complaintTimeForm.date_init_disagreement_stage)[0].id
        }
        this.$emit('formTimeComplaint', sendForm)
      },
      deep: true
    },
    'complaintTimeForm.time_damage_missing': {
      handler(value) {
        this.$emit('formTimeComplaint', {time_damage_missing: {...value}})
      },
      deep: true
    },
    'complaintTimeForm.time_claim_unawareness_delivery': {
      handler(value) {
        this.$emit('formTimeComplaint', {time_claim_unawareness_delivery: {...value}})
      },
      deep: true
    },
    'complaintTimeForm.time_wait_document': {
      handler(value) {
        this.$emit('formTimeComplaint', {time_wait_document: {...value}})
      },
      deep: true
    },
    'complaintTimeForm.compensation_limit_insurance': {
      handler(value) {
        this.$emit('formTimeComplaint', {compensation_limit_insurance: {...value}})
      },
      deep: true
    },
    'complaintTimeForm.time_restudy': {
      handler(value) {
        this.$emit('formTimeComplaint', {time_restudy: {...value}})
      },
      deep: true
    }
  },
  methods: {
    setObjetOptionDocument(textOption) {
      let result = 'Fecha de estado del ticket'
      if (textOption) result = this.optionsDocumentDate.filter(element => element.id === textOption)
      return result
    },
    setInitialData() {
      this.fieldsForm = [
        {useSlot:true, name: 'damageMissingSlot', label: 'Daños/Faltantes', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'unawarenessSlot', label: 'Desconocimiento Entrega', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'restudySlot', label: 'Tiempo Reestudio', containerClass: 'col-12 col-md-4 container-info'},
        {useSlot:true, name: 'waitDocumentSlot', label: 'Espera Documentación', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldSelect', name: 'date_init_wait_document', label: 'Fecha Inicio Entrega Documento', containerClass: 'col-12 col-md-4 container-info', options: this.optionsDocumentDate, searchOnType: { nChars: 999}},
        {fieldType: 'FieldSelect', name: 'date_init_disagreement_stage', label: 'Fecha inicio etapa discrepancia', containerClass: 'col-12 col-md-4 container-info', options: this.optionsDocumentDate, searchOnType: { nChars: 999}},
        {useSlot:true, name: 'insuranceMoneySlot', label: 'Monto Mínimo Solicitud Boleta/Factura', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldCheckbox', multiple: false, name: 'platform_complaint', label:'Reclamo en plataforma', containerClass: 'col-12 container-info', options: this.optionsPlatform}
      ]
      this.selectInputConditions = {
        damageMissingCondition: this.setConditionsSelectInput('time', 'Ingrese Plazo Reclamo Daño/Faltantes'),
        unawarenessCondition: this.setConditionsSelectInput('time', 'Ingrese Plazo Reclamo Desconocimiento'),
        waitDocumentCondition: this.setConditionsSelectInput('time', 'Ingrese Plazo Espera Documentación'),
        insuranceMoneyCondition: this.setConditionsSelectInput('currency', 'Ingrese Monto'),
        restudyTimeCondition: this.setConditionsSelectInput('time', 'Ingrese Tiempo de Reestudio')
      }
      this.setFormData(this.complaintTime)
    },
    setConditionsSelectInput(type, placeholder, required = '') {
      return {
        type,
        placeholder,
        required
      }
    },
    setFormData(data) {
      if (data) this.complaintTimeForm = {
        time_damage_missing: {
          time_type: data.time_damage_missing.time_type ?? 'DAYS',
          value: data.time_damage_missing.value ?? 0
        },
        time_claim_unawareness_delivery: {
          time_type: data.time_claim_unawareness_delivery.time_type ?? 'DAYS',
          value: data.time_claim_unawareness_delivery.value ?? 0
        },
        time_wait_document: {
          time_type: data.time_wait_document.time_type ?? 'DAYS',
          value: data.time_wait_document.value ?? 0
        },
        time_restudy: {
          time_type: data.time_restudy.time_type ?? 'DAYS',
          value: data.time_restudy.value ?? 0
        },
        date_init_wait_document: data.date_init_wait_document ? this.setObjetOptionDocument(data.date_init_wait_document)[0].id ?? '' : '',
        date_init_disagreement_stage: data.date_init_disagreement_stage ? this.setObjetOptionDocument(data.date_init_disagreement_stage)[0].id ?? '' : '',
        compensation_limit_insurance:{
          unit: data.compensation_limit_insurance.unit ?? 'USD',
          value: data.compensation_limit_insurance.value ?? 0
        },
        platform_complaint: data.platform_complaint ?? false

      }
    },
    updateInfoForm(value, source) {
      if ('time_type' in this.complaintTimeForm[source] && value.selector && value.selector.id) this.complaintTimeForm[source].time_type = value.selector.id 
      if ('unit' in this.complaintTimeForm[source] && value.selector && value.selector.id)  this.complaintTimeForm[source].unit = value.selector.id 
      if (value.textValue) this.complaintTimeForm[source].value = parseInt(value.textValue)
    }
  }
}
</script>
<style lang="scss">
  .collapse--space {
    visibility: collapse;
  }
</style>