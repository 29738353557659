<template>
  <div>
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="communicationForm" :fields="fieldsForm">
        </form-render>
      </template>
    </filter-swapper>
  </div>
</template>
<script>
export default {
  props: ['communication'],
  data() {
    return {
      communicationForm: {},
      fieldsForm: [],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      formUpdate: {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    communicationForm: {
      handler(value) {
        this.updateForm(value)
      },
      deep: true
    },
    communication: {
      handler(communicationData) {
        this.setFormData(communicationData)
      },
      deep: true
    }
  },
  methods: {
    setInitialData() {
      this.fieldsForm = [
        {fieldType: 'FieldInput', name: 'communication_channel_types', label: 'Link de plataforma para seguimientos', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'communication_channel_complaints', label: 'Usuario', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'communication_channel_complaints_detail', label: 'Contraseña', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'hide', label: '', containerClass: 'col-12 col-md-4 collapse--space', disabled:'disabled'},
        {fieldType: 'FieldInput', name: 'communication_channel_compensation', label: 'Link de plataforma para reclamos', containerClass: 'col-12 col-md-4 container-info'},
        {fieldType: 'FieldInput', name: 'communication_channel_compensation_detail', label: 'Link POD', containerClass: 'col-12 col-md-4 container-info'}
      ]
      this.setFormData(this.communication)
    },
    setFormData(data) {
      if (data) this.communicationForm = {
        communication_channel_types: data.types ?? '',
        communication_channel_complaints: data.complaints ?? '',
        communication_channel_complaints_detail: data.complaints_detail ?? '',
        communication_channel_compensation: data.compensation ?? '',
        communication_channel_compensation_detail: data.compensation_detail ?? ''
      }
    },
    updateForm(data) {
      this.formUpdate = {
        types: data.communication_channel_types ?? '',
        complaints: data.communication_channel_complaints ?? '',
        complaints_detail: data.communication_channel_complaints_detail ?? '',
        compensation: data.communication_channel_compensation ?? '',
        compensation_detail: data.communication_channel_compensation_detail ?? ''
      }
      this.$emit('formCommunication', this.formUpdate)
    }
  }
}
</script>
<style lang="scss">
</style>