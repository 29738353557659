<template>
  <div>
    <b-tabs content-class="mt-05" @input="tabChanged">
      <b-tab active class="no-body" v-if="notVersion">
        <template #title><feather-icon icon="FileTextIcon"/> Datos de contacto</template>
        <contact-information :contact="detail.contact" @formContact="updateForm($event, 'contact')">
      </contact-information></b-tab>
      <b-tab v-if="notVersion">
        <template #title> <icons :icon="'megaphoneIcon'"></icons> Canales de comunicación</template>
        <communication-channel :communication="detail.communication_channel" @formCommunication="updateForm($event, 'communication_channel')"></communication-channel>
      </b-tab>
      <b-tab>
        <template #title> <icons :icon="'ManageHistoryIcon'"></icons> Gestión de retiros</template>
        <management-pickups :communication="detail" @formManagePickup="updateForm($event, 'management_pickup')"></management-pickups>
      </b-tab>
      <b-tab>
        <template #title><feather-icon icon="ClockIcon" /> Tiempo Etapas</template>
        <time-stage :stageTime="detail.config_stage_time" @formTimeStage="updateForm($event, 'config_stage_time')"></time-stage>
      </b-tab>
      <b-tab>
        <template #title><feather-icon icon="ArchiveIcon"/> Tiempos de reclamo</template>
        <time-complaint :complaintTime="detail.complaint_time" @formTimeComplaint="updateForm($event, 'complaint_time')"></time-complaint>
      </b-tab>
      <b-tab>
        <template #title><feather-icon icon="DollarSignIcon"/> Parámetros indemnización</template>
        <compensation-config :compensationConfig="detail.compensation_config" @formCompensation="updateForm($event, 'compensation_config')" ></compensation-config>
      </b-tab>
    </b-tabs>
    <version-list v-if="notVersion" :versionList="detail.versions" :idCarrier="detail.configuration_id"></version-list>
    <div v-if="notVersion && permission.edit && !isPickupTab" class="mt-5 text-right">
      <b-button variant="outline-success" @click="sendForm">Guardar Cambios</b-button>
    </div>
    <div v-if="notVersion && permission.edit && isPickupTab" class="mt-5 text-right">
      <b-button variant="success" :disabled="loading.pickupFormBtn" @click="sendPickupForm">
        Guardar Cambios <i v-if="loading.pickupFormBtn" :class="['fa', 'fa-spinner', 'fa-spin']"/> 
      </b-button>
    </div>
  </div>
</template>
<script>
import ContactInformation from './ContactInformation.vue'
import ManagementPickups from './ManagementPickups.vue'
import CommunicationChannel from './CommunicationChannel.vue'
import TimeStage from './TimeStage.vue'
import TimeComplaint from './TimeComplaint.vue'
import CompensationConfig from './CompensationConfig.vue'
import BaseServices from '@/store/services/index'
import VersionList from './VersionList.vue'
import Icons from '@/Icons/Icons.vue'

export default {
  props: ['detail', 'notVersion'],
  components: {ContactInformation, ManagementPickups, CommunicationChannel, TimeStage, TimeComplaint, CompensationConfig, VersionList, Icons},
  data() {
    return {
      baseService: new BaseServices(this),
      form: {},
      permission: {
        edit: false
      },
      isPickupTab: false,
      loading: {
        pickupFormBtn: false
      }
    }
  },
  mounted() {
    if (this.notVersion) {
      this.form = {...this.detail}
    }
    this.setDataPermissions()
  },
  methods: {
    updateForm(data, source) {
      if (this.notVersion) {
        this.form[source] = {...this.form[source], ...data}
      }
    },
    sendForm() {
      if (this.notVersion) {
        const params = {
          shipper_id: this.$session.get('cas_user').id ? this.$session.get('cas_user').id : '',
          id_carrier: this.detail.configuration_id
        }
        const queryParams = {...this.detail, 
          contact: this.form.contact,
          communication_channel: this.form.communication_channel,
          config_stage_time: this.form.config_stage_time,
          complaint_time: this.form.complaint_time,
          compensation_config: this.form.compensation_config
        }
        this.baseService.callService('updateDetailCarrier', queryParams, params)
          .then(response => {
            this.$success('Configuración de Carrier actualizada correctamente')
          })
          .catch(err => {
            // this.$alert(this.$t('Ocurrió un problema al guardar, intenta de nuevo'))
            this.showErrors(err) 
            console.error(err)
          })
          .finally(() => {
            this.$emit('updateForm', this.detail.configuration_id)
          })
      }
    },
    sendPickupForm () {
      const queryParams = {
        cancelation_config: {
          type: 'pickup_date',
          params: {
            minutes_before_time_limit: Number(this.form.management_pickup.minutes_before_time_limit)
          },
          enabled: true
        }
      }
      this.loading.pickupFormBtn = true
      this.baseService.callService('setCarrierConfiguration', queryParams, { carrier_code: this.detail.carrier_code })
        .then(response => {
          this.$success('Configuración de retiros actualizada correctamente')
        })
        .catch(err => {
          // this.$alert(this.$t('Ocurrió un problema al guardar, intenta de nuevo'))
          this.showErrors(err) 
          console.error(err)
        })
        .finally(() => {
          this.loading.pickupFormBtn = false
        })
    },
    showErrors(err) {
      const text = 'Ocurrió un problema al guardar, intenta de nuevo'
      let textMessage = ''
      if (Array.isArray(err)) {
        textMessage += '<ul style="list-style-type: disc;">'
        err.forEach(element => {
          textMessage += `<li>${element.message}</li>`
        })
        textMessage += '</ul>'
      }
      this.$alert(textMessage ? textMessage : text)
    },
    setDataPermissions() {
      const permissionArray = this.$session.get('cas_user').special_permissions
      const editPermissions = ['edit-carrier-configs']
      this.permission = {
        edit: !!permissionArray.find((element) => editPermissions.includes(element))
      }
    },
    tabChanged(tab) {
      this.isPickupTab = tab === 2
    }
  }
}
</script>