<template>
  <div class="select--input--class">
    <filter-swapper  :controlHeightButtons="controlHeight">
      <template #slot1>
        <form-render :form.sync="selectInputForm" :fields="fieldsForm">
        </form-render>
      </template>
    </filter-swapper>
  </div>
</template>
<script>
export default {
  props:['conditions', 'valueInput'],
  data() {
    return {
      selectOption: [],
      selectInputForm: {
        textValue: 0,
        selector: {
          id: 'DAYS',
          text: 'DIAS',
          title: 'Dias',
          color: 'black'
        }
      },
      timeOptions: [
        {
          id: 'MINUTES',
          text: 'MIN',
          title: 'Minutos',
          color: 'black'
        },
        {
          id: 'HOURS',
          text: 'HORAS',
          title: 'Horas',
          color: 'black'
        },
        {
          id: 'DAYS',
          text: 'DIAS',
          title: 'Dias',
          color: 'black'
        },
        {
          id: 'MONTHS',
          text: 'MESES',
          title: 'Meses',
          color: 'black'
        }
      ],
      currencyOptions: [
        {
          id: 'USD',
          text: 'USD',
          title: 'Dólar estadounvalueense',
          color: 'black'
        },
        {
          id: 'ARS',
          text: 'ARS',
          title: 'Peso Argentino',
          color: 'black'
        },
        {
          id: 'CLP',
          text: 'CLP',
          title: 'Peso Chileno',
          color: 'black'
        },
        {
          id: 'COP',
          text: 'COP',
          title: 'Peso Colombiano',
          color: 'black'
        },
        {
          id: 'PEN',
          text: 'PEN',
          title: 'Sol Peruano',
          color: 'black'
        },
        {
          id: 'MXN',
          text: 'MXN',
          title: 'Peso Mexicano',
          color: 'black'
        },
        {
          id: 'UF',
          text: 'UF',
          title: 'UF',
          color: 'black'
        }
      ],
      controlHeight: { class: 'row mb-12 spacing-label-field'},
      fieldsForm: [],
      selectPlaceholder: ''
    }
  },
  mounted() {
    this.changeType()
    this.setFieldsForm()
  },
  watch: {
    selectInputForm: {
      handler (value) {
        this.$emit('updateDataSelectorInput', value)
      },
      deep: true
    }
  },
  methods: {
    changeType() {
      if (this.conditions.type) {
        switch (this.conditions.type) {
        case 'time':
          this.selectOption = []
          this.selectOption = [...this.timeOptions]
          this.selectPlaceholder = 'Seleccione unidad de tiempo'
          break
        case 'currency': 
          this.selectOption = []
          this.selectOption = [...this.currencyOptions]
          this.selectPlaceholder = 'Seleccione moneda'
          break
        default:
          this.selectOption = []
          this.selectOption = [...this.timeOptions]
          this.selectPlaceholder = 'Seleccione unidad de tiempo'
          break
        }
      }
    },
    setFieldsForm() {
      this.fieldsForm = [
        {fieldType: 'FieldInput', name: 'textValue', containerClass: 'col-8 input--class--component container-info', type: 'number'},
        {fieldType: 'FieldSelect', name: 'selector', placeholder: this.selectPlaceholder, containerClass: 'col-4 select--class--component container-info', validation: this.conditions.required, options: this.selectOption, searchOnType: { nChars: 999}}
      ]
      this.setValueInput()
    },
    setValueInput() {
      this.selectInputForm.textValue = this.valueInput?.value ?? 0
      if ('time_type' in this.valueInput) {
        const valueTime = this.selectOption.filter(el => el.id === (this.valueInput.time_type ? this.valueInput.time_type.toUpperCase() : 'DAYS'))[0]
        this.setValueSelector(valueTime)
      }
      if ('unit' in this.valueInput) {
        const valueCurrency =  this.selectOption.filter(el => el.id === (this.valueInput.unit ? this.valueInput.unit.toUpperCase() : 'USD'))[0]
        this.setValueSelector(valueCurrency)
      }
    },
    setValueSelector(data) {
      if (data) {
        this.selectInputForm.selector.id = data.id ? data.id : null
        this.selectInputForm.selector.text = data.text ? data.text : ''
        this.selectInputForm.selector.title = data.title ? data.title : ''
        this.selectInputForm.selector.color = data.color ? data.color : 'black'
      }
    }
  }
}
</script>
<style lang="scss" >
   .select--input--class {
    .input--class--component {
      padding-right: 0;
      .form-control{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .select--class--component {
      padding-left: 0;
      .vs__dropdown-toggle {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-bottom: 5px;
      }
    }
  }
  
</style>