<template>
    <b-modal id="modalVersionCarrier" :title="'Detallado de version'" size="xl" centered text class="modal--detail--carrier" hide-footer>
    <body-detail :detail="detail" ></body-detail>
  </b-modal>
</template>
<script>
export default {
  props: ['detail'],
  components: {BodyDetail: () => import('./BodyDetail.vue')}
}
</script>